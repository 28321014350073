const dev = {
    auth0: {
        domain: "pestex.eu.auth0.com",
        clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ",
    },
    api: {
        private_url: "https://p2sm60fb78.execute-api.eu-west-2.amazonaws.com",
        public_url: "https://bqp6b8wknd.execute-api.eu-west-2.amazonaws.com"
    },
    sentry: {
        enabled: true,
    },
};

const prod = {
    auth0: {
        domain: "pestex.eu.auth0.com",
        clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ",
    },
    api: {
        private_url: "https://31z66kaiwh.execute-api.eu-west-2.amazonaws.com",
        public_url: "https://dlib0mavh7.execute-api.eu-west-2.amazonaws.com"
    },
    sentry: {
        enabled: true,
    },
};

// Default to dev if not set
const config = (function () {
    switch (process.env.REACT_APP_STAGE) {
        case "prod":
            return prod;
        case "local":
        case "dev":
            return dev;
        default:
            return dev;
    }
})(dev);

export default {
    ...config,
};
