import * as THREE from "three";
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
import Multiplayer from "./Multiplayer2";
// import UnlitImageMaterial from "./UnlitMaterial";
//import {RecordEvent} from "../libs/AnalyticsLib";

export default class AvatarBuilder {
    //not started yet
    width = 3.2;
    height = 1.8;
    currentIndex = 0;

    constructor(renderer, uuid, scene, items, width, height, controlsOffset, hideControls) {
        this.renderer = renderer;
        this.uuid = uuid;
        this.scene = scene;
        this.items = items;
        this.width = width || 3.2;
        this.height = height || 1.8;
        this.controlsOffset = 0.0;

        Multiplayer.instance.registerCustomEventHandler(this.uuid, this);

        const buttonSize = 0.25;

        this.loader = new THREE.TextureLoader();


        const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);
        const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.5 }  );

        const board = new THREE.Mesh(geometry, material);
        this.board = board;
        scene.add(this.board);

        if (!hideControls) {
            this.loader.load("assets/icons/ZoneVS-arrow-Wb.png", (texture) => {
                const material = new THREE.MeshBasicMaterial({
                    map: texture, transparent: true, opacity: 1.0
                });
                // const geometry = new THREE.PlaneBufferGeometry(buttonSize/2, buttonSize/2);
                const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);

                const control = new THREE.Mesh(geometry, material);
                control.position.x = -this.width / 2 - buttonSize / 2 - 0.1;
                control.position.y = controlsOffset;
                control.position.z = 0.05;
                this.board.add(control);
                new Clickable(control, this.previous);

                const showHelp = () => {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click here to view the previous artwork";
                };

                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    control,
                    () => {
                        document.body.style.cursor = "pointer";
                        showHelp();
                        control.scale.setScalar(1.2);
                    },
                    () => {
                        control.scale.setScalar(1);
                        hideHelp();
                    }
                );
            });

            this.loader.load("assets/icons/ZoneVS-arrow-Eb.png", (texture) => {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
                const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);
                const control = new THREE.Mesh(geometry, material);
                control.position.x = this.width / 2 + buttonSize / 2 + 0.1;
                control.position.y = controlsOffset;
                control.position.z = 0.05;
                this.board.add(control);
                new Clickable(control, this.next);

                const showHelp = () => {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click here to view the next Artwork";
                };

                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    control,
                    () => {
                        document.body.style.cursor = "pointer";
                        showHelp();
                        control.scale.setScalar(1.2);
                    },
                    () => {
                        control.scale.setScalar(1);
                        hideHelp();
                    }
                );
            });

            //todo - this needs to be optional - controlled by scene
            // this.loader.load("assets/icons/buyNFT.png", (texture) => {
            //     const material = new THREE.MeshBasicMaterial({
            //         map: texture,
            //     });
            //     const geometry = new THREE.CircleGeometry(buttonSize / 1, 32)
            //     const control = new THREE.Mesh(geometry, material);
            //     // control.position.x = -this.width / 2 - buttonSize / 2 - 0.1;
            //     control.position.z = 0.05;
            //     control.position.y = -this.height / 2 - buttonSize / 1 - 0.1;
            //     this.board.add(control);
            //     new Clickable(control, this.buy);
            //
            //     const showHelp = () => {
            //         document.getElementById("help-text").style.display = "block";
            //         document.getElementById("help-text").innerHTML = "Click here to buy this NFT";
            //     };
            //
            //     const hideHelp = () => (document.getElementById("help-text").style.display = "none");
            //
            //     new Hoverable(
            //         control,
            //         () => {
            //             document.body.style.cursor = "pointer";
            //             showHelp();
            //             control.scale.setScalar(1.2);
            //         },
            //         () => {
            //             control.scale.setScalar(1);
            //             hideHelp();
            //         }
            //     );
            // });

        }

        if (this.items) this.setImage(this.items[0]);
    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) => {
        this.board.rotateX(x * THREE.Math.DEG2RAD);
        this.board.rotateY(y * THREE.Math.DEG2RAD);
        this.board.rotateZ(z * THREE.Math.DEG2RAD);
    };

    setImage(image, skipSend) {
        if (!skipSend) {
            Multiplayer.instance.sendCustomEvent(this.uuid, "setImageRemote", {
                index: this.items.findIndex(x => x.id === image.id),
            });
        }

        this.loader.load(image.imageUrl, (texture) => {
            texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
            texture.minFilter = THREE.LinearFilter;
            const material = new THREE.ShaderMaterial({ fragmentShader: this.fragmentShader(), vertexShader: this.vertexShader(),
                            uniforms: { tex: { type: "t", value: texture }, }, transparent: true, });
            this.board.material = material;
            this.board.material.needsUpdate = true;
        });

    }

    setImageRemote(params) {
        const image = this.items[params.index];
        this.setImage(image, true);
    }

    next = () => {
        if (!this.items) return;
        // if(this.currentIndex === this.images.length - 1) return;
        if (this.currentIndex === this.items.length - 1) this.currentIndex = -1;
        this.currentIndex++;
        this.setImage(this.items[this.currentIndex]);
    };

    previous = () => {
        if (!this.items) return;
        // if(this.currentIndex === 0) return;
        if (this.currentIndex === 0) this.currentIndex = this.items.length;
        this.currentIndex--;
        this.setImage(this.items[this.currentIndex]);
    };

    buy = () => {
        const image = this.items[this.currentIndex]
        // window.open("https://www.woocommerce.com/", "_blank");
        window.open(image.shopUrl, "_blank");
        console.log(`cha-ching!!`);
    };


    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
    }

    //need to change this to use png alpha
    fragmentShader() {
        return `
            uniform sampler2D tex;
            uniform bool enabled;
            varying vec2 vUv;

            void main () {
                mediump vec3 inColour = texture2D(tex, vUv).rgb;

                    mediump vec3 green = vec3(0.0, 1.0, 0.0);
                    mediump float a = (length(inColour - green) - 0.5) * 7.0;
                    gl_FragColor = vec4(inColour, a);
            }
        `;
    }

}
