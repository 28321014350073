import { Button, DialogActions } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";

function DialogIncompatibleMobil(props){

    const handleClose = () => {
        props.onClose();
    }

    return (
        <Dialog {...props}>
            <DialogTitle>Cannot join meeting</DialogTitle>
            <DialogContent>
                To join meetings and enjoy this site at it's best please use a chrome based browser on a desktop computer.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogIncompatibleMobil;