export default class Hoverable {
    static hoverables = new Map();

    hovered = false;

    constructor(object, onHover, onUnhover, meta) {
        this.object = object;
        this.onHover = onHover;
        this.onUnhover = onUnhover;
        this.meta = meta;
        Hoverable.hoverables.set(object.uuid, this);
    }

    handleHovered() {
        this.hovered = true;
        if (this.onHover) {
            this.onHover(this);
        }
    }

    handleUnhover() {
        this.hovered = false;
        if (this.onUnhover) {
            this.onUnhover(this);
        }
    }

    dispose() {
        delete Hoverable.hoverables.delete(this.object.uuid);
    }
}
