import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    MenuItem,
    Paper, Slider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { LocalizationProvider } from "@mui/lab";
import { Fragment, useState } from "react";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ROOMS } from "../core/Scene";
import config from '../config'

export default function DialogScheduleMeeting(props) {
    const [room, setRoom] = useState("exhibitionSpace");
    const [date, setDate] = useState(new Date());
    const [attendees, setAttendees] = useState([]);
    const [email, setEmail] = useState("chris33dd@gmail.com");
    const [loading, setLoading] = useState(false);
    const [successLink, setSuccessLink] = useState();
    const [linkLifetime, setLinkLifetime] = useState(2);

    const { getAccessTokenSilently, user } = useAuth0();

    const handleChangeDate = (newValue) => {
        setDate(newValue);
    };

    const handleChangeRoom = (event) => {
        setRoom(event.target.value);
    };

    const handleAddAttendee = () => {
        if (attendees.includes(email)) {
            return;
        }
        const tmp = [...attendees];
        tmp.push(email);
        setAttendees(tmp);
        setEmail("");
    };

    const handleRemoveAttendee = (index) => {
        const tmp = [...attendees];
        tmp.splice(index, 1);
        setAttendees(tmp);
    };

    const handleConfirm = async () => {
        setLoading(true);

        const token = await getAccessTokenSilently({
            audience: "zonevs",
        });

        const response = await axios({
            url: `${config.api.private_url}/meetings/create`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                room,
                startsAt: date.toISOString(),
                linkLifetime,
                attendees: [...attendees, user.email],
            },
        });

        setSuccessLink(response.data.link);
        setLoading(false);

        // handleClose();
    };

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return;

        setAttendees([]);
        setDate(new Date());
        setSuccessLink(undefined);

        if (props.onClose) {
            props.onClose(event, reason);
        }
    };

    const handleChangeLinkLifetime = (event) => {
        setLinkLifetime(Number.parseFloat(event.target.value))
    }

    return (
        <Dialog maxWidth="sm" fullWidth {...props} onClose={handleClose}>
            <DialogTitle>Schedule Meeting</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <Stack spacing={2} sx={{ py: 2 }}>
                        {!successLink && (
                            <>
                                <div>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Date and Time
                                    </Typography>
                                    <Divider />
                                </div>

                                <DateTimePicker
                                    onChange={handleChangeDate}
                                    value={date}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <div>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Link lifetime (hours)
                                    </Typography>
                                    <Divider />
                                </div>

                                <Slider
                                    onChange={handleChangeLinkLifetime}
                                    value={linkLifetime}
                                    min={1}
                                    valueLabelDisplay="auto"
                                    max={24}
                                    step={1}
                                    marks

                                />

                                <div>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Room
                                    </Typography>
                                    <Divider />
                                </div>

                                <TextField select onChange={handleChangeRoom} value={room}>
                                    <MenuItem value="" disabled={true}>
                                        Select a room
                                    </MenuItem>
                                    {ROOMS.map((room) => (
                                        <MenuItem key={room.id} value={room.id}>
                                            {room.name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <div>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Manage Attendees
                                    </Typography>
                                    <Divider />
                                </div>

                                <Box display="flex">
                                    <Box flexGrow={1} mr={1}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                    </Box>
                                    <Button size="small" variant="outlined" onClick={handleAddAttendee}>
                                        Add
                                    </Button>
                                </Box>

                                <Paper>
                                    <List dense subheader={<ListSubheader>Attendees</ListSubheader>}>
                                        {attendees.length === 0 && (
                                            <ListItem>
                                                <ListItemText secondary="No attendees added" />
                                            </ListItem>
                                        )}
                                        {attendees.map((attendee, index) => (
                                            <Fragment key={attendee}>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            edge="end"
                                                            onClick={() => handleRemoveAttendee(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText primary={attendee} />
                                                </ListItem>
                                                {index !== attendees.length - 1 && <Divider />}
                                            </Fragment>
                                        ))}
                                    </List>
                                </Paper>
                            </>
                        )}
                        {successLink && (
                            <Alert severity="success" sx={{ overflow: "hidden" }}>
                                <AlertTitle>Successfully created meeting</AlertTitle>
                                {/*<Link href={successLink} target="_blank">*/}
                                    <Link href={successLink} target="">
                                    {successLink}
                                </Link>
                            </Alert>
                        )}
                    </Stack>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                {successLink && <Button onClick={handleClose}>Close</Button>}
                {!successLink && (
                    <>
                        <Button onClick={handleClose} disabled={loading}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} disabled={loading || attendees.length === 0 || room === ""}>
                            Send
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
