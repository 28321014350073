// Possibly old code

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { observer } from "mobx-react";

const DialogMultiplayerJoin = observer((props) => {
    const { open, multiplayer, onClose } = props;

    console.log(props);

    const [channel, setChannel] = useState(props.channelId);

    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);

    const [selectedVideo, setSelectedVideo] = useState("");
    const [selectedAudio, setSelectedAudio] = useState("");

    const handleChangeId = (event) => {
        const id = event.target.value;
        setChannel(id);
    };

    const handleJoin = () => {
        multiplayer.join(channel, selectedVideo, selectedAudio);
        onClose();
    };

    useEffect(() => {
        AgoraRTC.getDevices().then((devices) => {
            const audioDevices = devices.filter(function (device) {
                return device.kind === "audioinput";
            });

            setAudioDevices(audioDevices);
            setSelectedAudio(audioDevices[0].deviceId);

            const videoDevices = devices.filter(function (device) {
                return device.kind === "videoinput";
            });

            setVideoDevices(videoDevices);
            setSelectedVideo(videoDevices[0].deviceId);
        });
    }, []);

    console.log(selectedVideo);
    console.log(selectedAudio);
    console.log(channel);

    return (
        <Dialog open={open} fullWidth {...props}>
            <DialogTitle>Join meeting</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Camera"
                            fullWidth
                            select
                            variant="outlined"
                            value={selectedVideo}
                            onChange={(event) =>
                                setSelectedVideo(event.target.value)
                            }
                        >
                            {videoDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Microphone"
                            fullWidth
                            select
                            variant="outlined"
                            value={selectedAudio}
                            onChange={(event) =>
                                setSelectedAudio(event.target.value)
                            }
                        >
                            {audioDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            label="Enter your meeting code here"
                            fullWidth
                            variant="outlined"
                            value={channel}
                            onChange={handleChangeId}
                        />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <Button
                            onClick={handleJoin}
                            disabled={
                                selectedAudio === "" ||
                                selectedVideo === "" ||
                                !channel
                            }
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            Join
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
});

export default DialogMultiplayerJoin;
