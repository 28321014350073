import * as THREE from "three";
import Clickable from "./Clickable";
// import Hoverable from "./Hoverable";

export default class WhiteboardControls {
    // Add what you want here
    colors = [
        new THREE.Color("red"),
        new THREE.Color("green"),
        new THREE.Color("blue"),
        new THREE.Color("yellow"),
        new THREE.Color("cyan"),
        new THREE.Color("magenta"),
        new THREE.Color("black"),
        new THREE.Color("white"),
    ];
    colorsBG = [
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
        new THREE.Color("black"),
    ];

    // Tools to display on the right side of the board
    tools = [
        {
            name: "Paint",
            texture: "assets/icons/ZoneVS-pen.png",
            onClick: () => {
                this.whiteboard.enableDrawingMode();
                this.whiteboard.color = new THREE.Color("black");
            },
        },
        {
            name: "Text",
            texture: "assets/icons/ZoneVS-text.png",
            onClick: () => {
                this.whiteboard.enableTextMode();
            },
        },
        {
            name: "Eraser",
            texture: "assets/icons/ZoneVS-eraser.png",
            onClick: () => {
                this.whiteboard.enableEraser();
            },
        },
        {
            name: "Screenshot",
            texture: "assets/icons/ZoneVS-downloadb.png",
            onClick: () => {
                // this.whiteboard.takeScreenshotMainCamera();
                this.whiteboard.takeScreenshot();
            },
        },
        {
            name: "Clear",
            texture: "assets/icons/ZoneVS-trash.png",
            onClick: () => {
                this.whiteboard.clear();
            },
        },
    ];

    constructor(whiteboard, scene, size) {
        this.whiteboard = whiteboard;
        this.scene = scene;
        this.loader = new THREE.TextureLoader();

        const buttonSize = size || 0.17;

        this.colors.forEach((color, i) => {
            const x = -this.whiteboard.width / 2 - buttonSize / 2;
            const y = this.whiteboard.height / 2 - buttonSize / 2 - (i * buttonSize) / 1.2;

            // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
            // const geometry = new THREE.BoxGeometry(buttonSize / 1.2, buttonSize / 1.2, buttonSize / 15.0);
            const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
            // const geometryBorder = new THREE.BoxGeometry(buttonSize / 1.3, buttonSize / 1.3, buttonSize / 15.0);
            const material = new THREE.MeshBasicMaterial({ color });
            const control = new THREE.Mesh(geometry, material);

            control.position.x = x + 0.03;
            control.position.y = y - 0.02;
            // control.position.z = .10;
            this.whiteboard.board.add(control);

            // const geometryBorder = new THREE.BoxGeometry(buttonSize, buttonSize / 0.80, buttonSize / 50);
            const geometryBorder = new THREE.CircleGeometry(buttonSize / 2.6, 32);
            const materialBG = new THREE.MeshBasicMaterial({ color: "black" });
            const controlBG = new THREE.Mesh(geometryBorder, materialBG);
            controlBG.position.x = x + 0.03;
            controlBG.position.y = y - 0.02;
            controlBG.position.z = -0.001;
            this.whiteboard.board.add(controlBG);

            // needs to find the controls
            // new Hoverable(this.whiteboard.board.control, () => {
            //     document.body.style.cursor = 'pointer';
            // });

            // new Hoverable(control, () => {
            //     document.body.style.cursor = 'pointer';
            // });

            const handleClick = (clickable) => {
                this.whiteboard.color = clickable.object.material.color;
                this.whiteboard.enableDrawingMode();
            };

            new Clickable(control, handleClick);
        });

        this.tools.forEach((tool, i) => {
            const x = this.whiteboard.width / 2 + buttonSize / 2;
            const y = this.whiteboard.height / 2 - buttonSize / 1.95 - (i * (buttonSize * 1.14) + 0.115);

            //const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
            const geometry = new THREE.CircleGeometry(buttonSize / 1.7, 32);

            this.loader.load(tool.texture, (texture) => {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });

                const control = new THREE.Mesh(geometry, material);

                control.position.x = x + 0.03;
                control.position.y = y * 1.2;
                control.position.z = -0.01;
                this.whiteboard.board.add(control);

                const handleClick = (clickable) => {
                    clickable?.meta?.tool?.onClick();
                };

                new Clickable(control, handleClick, {
                    tool,
                });
            });
        });
    }
}
