import AgoraRTM from "agora-rtm-sdk";
import AgoraRTC from "agora-rtc-sdk-ng";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import LiteEvent from "../libs/LiteEvent";
import { makeAutoObservable } from "mobx";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import { RAYCAST_EXCLUDE_LAYER } from "../core/Scene";

const qrate = require("qrate");
const queue = require("fastq").promise(QueueWorker, 1);

const appId = "b788346730fa4d65b529a644ee50b320";

const baseRotation = new THREE.Quaternion();
baseRotation.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);

async function QueueWorker(task) {
    return await task();
}

export default class Multiplayer {
    static instance;

    joined = false;

    lastUpdateTime = 0;
    options = {};
    users = [];

    audio_muted = false;
    video_muted = false;
    mic_muted = false;

    customEventHandlerMap = new Map();

    onRemoteUserStartScreenShare = new LiteEvent();
    onRemoteUserStopScreenShare = new LiteEvent();

    constructor(scene, options) {
        makeAutoObservable(this);

        Multiplayer.instance = this;

        this.scene = scene;
        this.options = options;

        this.q = qrate(this.processQueuedMessageWorker, 60, 60);
    }

    updateOptions = (newOptions) => {
        this.options = {
            ...this.options,
            ...newOptions
        }
    }

    join = async (channel, cameraId, microphoneId) => {
        this.channel_id = channel;

        let selectedCamera = cameraId;
        let selectedMicrophone = microphoneId;

        if (!cameraId || !microphoneId) {
            const devices = await AgoraRTC.getDevices();

            const audioDevices = devices.filter((device) => {
                return device.kind === "audioinput";
            });

            selectedMicrophone = audioDevices[0].deviceId;

            const videoDevices = devices.filter((device) => {
                return device.kind === "videoinput";
            });

            selectedCamera = videoDevices[0].deviceId;
        }

        //Setup RTC
        this.rtc_client = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
            role: "host",
        });

        this.rtc_client_screen = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
            role: "host",
            // audio: false,
            // video: false,
            // screen: true,
        });

        this.handleRtcEvents();

        // Setup RTM
        this.rtm_client = AgoraRTM.createInstance(appId, {
            enableLogUpload: false,
            logFilter: AgoraRTM.LOG_FILTER_ERROR,
        });
        this.rtm_channel = this.rtm_client.createChannel(channel);
        this.handleRtmEvents();

        try {
            this.uid = await this.rtc_client.join(appId, channel, null, null);
            console.log("rtc_client join success");

            // Init screen share client
            this.uid_screen = await this.rtc_client_screen.join(
                appId,
                `${channel}-screen`,
                null,
                null
            );
            console.log("rtc_client_screen join success");

            // Start RTM init
            await this.rtm_client.login({ token: null, uid: String(this.uid) });
            console.log("rtm_client login success.");

            await this.rtm_channel.join();
            this.rtm_joined = true;
            console.log("rtm_channel join success.");
            // End RTM init

            this.localMicrophone = await AgoraRTC.createMicrophoneAudioTrack({
                selectedMicrophone,
            });

            this.localVideo = await AgoraRTC.createCameraVideoTrack({
                selectedCamera,
            });
            console.log("create local audio/video track success");

            await this.rtc_client.publish([this.localMicrophone, this.localVideo]);
            console.log("publish success");

            //vanity square
            //Create a small video on the top left so the user can see their own video
            //todo - make this square more info-rich eg : "connected / hang up ! etc
            const video = document.createElement("video");
            video.id = "local-video-view";
            video.style.position = "absolute";
            video.style.top = "20px";
            // video.style.left = "20px";
            video.style.right = "20px";
            video.style.width = "160px";
            video.style.height = "90px";
            video.srcObject = new MediaStream([
                this.localVideo.getMediaStreamTrack(),
            ]);
            await video.play();

            this.local_video_element = video;

            const root = document.getElementById("root");
            root.appendChild(video);

            if (this.options.onJoin) {
                this.options.onJoin();
            }

            this.joined = true;
        } catch (e) {
            console.log("join failed");
            console.error(e);
        }
    };

    processQueuedMessageWorker = async (data, done) => {
        const { id, functionName, functionArgs } = data;
        await this.sendCustomChannelMessage(id, functionName, functionArgs);
        done();
    };

    sendCustomChannelMessage = async (id, functionName, functionArgs) => {
        await this.sendChannelMessage(
            "custom",
            JSON.stringify({
                id,
                functionName,
                functionArgs,
            })
        );
    }

    leave = async () => {
        //Leave RTC
        try {
            this.localMicrophone.stop();
            this.localMicrophone.close();

            this.localVideo.stop();
            this.localVideo.close();

            this.rtc_client.remoteUsers.forEach((user) => {
                document.getElementById("face-video-" + user.uid).remove();
            });

            await this.rtc_client.leave();
        } catch (e) {
            console.log("Error in rtc_client leave.");
            console.error(e);
        }

        //Leave RTM
        try {
            await this.rtm_client.logout();
            this.rtm_channel = null;
            this.rtm_client = null;
            console.info("RTM leave success.");
        } catch (err) {
            console.info("RTM leave failure.");
            console.error(err);
        }

        //Cleanup user models
        for (let i = 0; i < this.users.length; i++) {
            const user = this.users[i];
            this.scene.remove(user);
        }

        this.users = [];

        if (this.options.onLeave) {
            this.options.onLeave();
        }
    };

    handleRtmEvents = () => {
        // event listener for receiving a channel message
        this.rtm_channel.on("ChannelMessage", async (event, sender_id) => {
            // convert from string to JSON
            const msg = JSON.parse(event.text);

            switch (msg.action) {
                case "position": {
                    const { position, instant } = msg.data;
                    await this.moveModel(sender_id, position, instant);
                    break;
                }
                case "rotation": {
                    await this.rotateModel(
                        sender_id,
                        this.unpackQuaternion(msg.data)
                    );
                    break;
                }
                case "custom": {
                    const data = JSON.parse(msg.data);
                    const handler = this.customEventHandlerMap.get(data.id);
                    handler[data.functionName](data.functionArgs);
                }
            }
        });
    };

    handleRtcEvents = () => {
        this.rtc_client.on("user-joined", async (user) => {
            await queue.push(async () => {
                console.log("User joined ");

                const loader = new GLTFLoader();
                const gltf = await loader.loadAsync("assets/models/client.glb");
                const model = gltf.scene;
                model.name = user.uid;
                model.scale.setScalar(0.7);
                model.userData.init = false;
                model.visible = false;

                const video = document.createElement("video");
                video.poster="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2F29S4LuG14Us%2Fmaxresdefault.jpg&f=1&nofb=1"
                video.style.display = "none";
                video.id = "face-video-" + user.uid;
                video.setAttribute("webkit-playsinline", "webkit-playsinline");
                video.setAttribute("playsinline", "playsinline");
                video.muted = true;

                const root = document.getElementById("root");
                root.appendChild(video);

                const texture = new THREE.VideoTexture(video);
                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearFilter;
                texture.flipY = false;

                model.traverse((node) => {
                    // search the mesh's children for the face-geo
                    if (node.isMesh && node.name === "face-geo") {
                        node.material.map = texture;
                    }
                });

                this.scene.add(model);
                this.users.push(model);

                if (this.options.onRemoteUserJoin) {
                    this.options.onRemoteUserJoin();
                }
            });
        });

        this.rtc_client.on("user-left", async (user, reason) => {
            document.getElementById("face-video-" + user.uid)?.remove();

            // Remove three scene object
            const remote_user = this.users.find((u) => user.uid === u.name);

            if (remote_user) {
                this.scene.remove(remote_user);
            }

            this.users = this.users.filter((u) => user.uid !== u.name);

            if (this.options.onRemoteUserLeave) {
                this.options.onRemoteUserLeave();
            }

            if (
                this.options.autoLeave &&
                this.rtc_client.remoteUsers.length === 0
            ) {
                await this.leave();
            }
        });

        this.rtc_client.on("user-published", async (remoteUser, mediaType) => {
            await queue.push(async () =>
            {
                console.log("User published ");
                await this.rtc_client.subscribe(remoteUser, mediaType);

                if (mediaType === "video") {
                    const video = document.getElementById(
                        "face-video-" + remoteUser.uid
                    );
                    video.srcObject = new MediaStream([
                        remoteUser.videoTrack.getMediaStreamTrack(),
                    ]);
                    await video.play();
                }

                if (mediaType === "audio") {
                    console.log("subscribe audio success");

                    if(this.options.positionalAudioEnabled){

                        console.info("Positional audio enabled!")

                        const model = this.scene.getObjectByName(remoteUser.uid);
                        const stream = new MediaStream([
                            remoteUser.audioTrack.getMediaStreamTrack(),
                        ]);
                        //
                        // // https://threejs.org/docs/index.html?q=positi#api/en/audio/PositionalAudio.panner
                        // // https://developer.mozilla.org/en-US/docs/Web/API/PannerNode

                        const positionalAudio = new THREE.PositionalAudio(
                            this.options.engine.listener
                        );
                        positionalAudio.play();
                        positionalAudio.setMediaStreamSource(stream);
                        positionalAudio.setRefDistance(4);
                        positionalAudio.setMaxDistance(5);
                        positionalAudio.setRolloffFactor(5);
                        positionalAudio.setDirectionalCone( 180, 230, 0.1 );
                        model.add(positionalAudio);

                        if(this.options.positionalAudioHelperEnabled) {
                            const helper = new PositionalAudioHelper(positionalAudio, 2);
                            helper.layers.disableAll();
                            helper.layers.enable(RAYCAST_EXCLUDE_LAYER);
                            positionalAudio.add(helper);
                        }
                    }else {
                        remoteUser.audioTrack.play();
                    }
                }
            })
        });

        this.rtc_client.on(
            "user-unpublished",
            async (remoteUser, mediaType) => {
                console.log(remoteUser);
                console.log(mediaType);

                if (mediaType === "video") {
                    const video = document.getElementById(
                        "face-video-" + remoteUser.uid
                    );
                    video.srcObject = undefined;
                    video.src = "/assets/videos/grimes.mp4";
                    video.loop = true;
                    await video.play();
                }
            }
        )

        this.rtc_client_screen.on("user-joined", async (user) => {
            await queue.push(async () => {
                console.log("Screen User joined ");

                const video = document.createElement("video");
                video.style.display = "none";
                video.id = "screen-video-" + user.uid;
                video.setAttribute("webkit-playsinline", "webkit-playsinline");
                video.setAttribute("playsinline", "playsinline");
                const root = document.getElementById("root");
                root.appendChild(video);
            });
        });

        this.rtc_client_screen.on("user-left", async (user) => {
            await queue.push(async () => {
                console.log("Screen User left ");
            });
        });

        this.rtc_client_screen.on(
            "user-published",
            async (remoteUser, mediaType) => {
                // This will prevent the sharer to see their own screen in the scene.
                // if(remoteUser.uid === this.uid_screen) return;

                await this.rtc_client_screen.subscribe(remoteUser, mediaType);

                if (mediaType === "video") {
                    // const video = document.getElementById('screen-video-' + remoteUser.uid);
                    const video = document.getElementById("screen-share");
                    const track = remoteUser.videoTrack.getMediaStreamTrack();
                    video.srcObject = new MediaStream([track]);
                    await video.play();

                    this.onRemoteUserStartScreenShare.trigger(remoteUser);
                }

                if (mediaType === "audio") {
                    console.log("subscribe audio success");
                    remoteUser.audioTrack.play();
                }
            }
        );
        this.rtc_client_screen.on(
            "user-unpublished",
            async (remoteUser, mediaType) => {
                this.onRemoteUserStopScreenShare.trigger(remoteUser);
            }
        );
    };

    sendChannelMessage = async (action, data) => {
        if (this.rtm_channel && this.rtm_joined) {
            // Build the Agora RTM Message
            const msg = {
                description: undefined,
                messageType: "TEXT",
                rawMessage: undefined,
                text: JSON.stringify({
                    action,
                    data,
                }),
            };

            try {
                await this.rtm_channel.sendMessage(msg);
            } catch (err) {
                console.log("Error sending channel message.");
                console.error(err);
            }
        }
    };

    moveSelf = async (position, instant) => {
        this.position = position;
        await this.moveModel(this.uid, position, instant, true);
    };

    moveModel = async (uid, position, instant, send) => {
        const timeNow = new Date().getTime();

        if (send) {
            if (this.lastUpdateTime + 150 < timeNow) {
                this.lastUpdateTime = timeNow;
                await this.sendChannelMessage("position", {
                    position,
                    instant,
                });
            }
        } else {
            const model = this.users.find((u) => u.name == uid);
            if (model) {
                if (instant) {
                    model.position.set(position.x, position.y, position.z);
                } else {
                    model.userData.target = position;
                }

                if (!model.userData.init) {
                    model.visible = true;
                }
            } else {
                console.log("Failed to find matching model for UID");
            }
        }
    };

    packQuaternion = (q) => {
        return JSON.stringify({
            x: q.x,
            y: q.y,
            z: q.z,
            w: q.w,
        });
    };

    unpackQuaternion = (s) => {
        let o = JSON.parse(s);
        let q = new THREE.Quaternion(o.x, o.y, o.z, o.w);
        q.multiply(baseRotation);
        return q;
    };

    rotateSelf = async (q, force) => {
        await this.rotateModel(this.uid, q, true, force);
        //NOTE TO GEORGE rotating self - this calls rotateModel
    };

    rotateModel = async (uid, q, send, force) => {
        const timeNow = new Date().getTime();

        if (send) {
            if (force || this.lastUpdateTime + 150 < timeNow) {
                this.lastUpdateTime = timeNow;

                //NOTE TO GEORGE called by rotateSelf - we send the current viewpoint rotation (just Y for now) via Agora RTM from LookcontrolsV2.js
                await this.sendChannelMessage(
                    "rotation",
                    this.packQuaternion(q)
                );
            }
        } else {
            const model = this.users.find((u) => u.name == uid);

            //NOTE TO GEORGE  - called by rotateSelf - we receive the remote viewpoint rotation (just Y for now) via Agora RTM from LookcontrolsV2.js
            // console.log("DEBUG: Receiving rotation: " + q)

            if (model) {
                //NOTE TO GEORGE  We are not handling the euler / Quaternion / rotation  etc properly - needs to be refined
                // Harry original
                // model.rotation.y = y + Math.PI;

                model.quaternion.copy(q);
            }
        }
    };

    update = () => {
        this.users.forEach((user) => {
            // const videoElement = document.getElementById('face-video-' + user.name);
            //
            //
            // if(videoElement) {
            //     const distance = user.position.distanceTo(this.selfPosition || new THREE.Vector3())
            //     console.log(`Distance to ${user.name} : ${distance}`)
            //
            //     if(distance > 2) {
            //         videoElement.muted = true;
            //     }else{
            //         videoElement.muted = false;
            //     }
            // }

            const posTarget = user.userData.target;

            if (posTarget) {
                if (user.position.distanceTo(posTarget) > 0.1) {
                    user.position.lerp(posTarget, 0.03);
                } else {
                    user.userData.target = undefined;
                }
            }

            // const rotTarget = user.userData.target;
            //
            // if (rotTarget) {
            //     user.rotation.lerp(rotTarget, 0.03);
            // }
        });
    };

    muteAudio = () => {
        if(this.localMicrophone){
            this.localMicrophone.setMuted(true)
            this.audio_muted = true;
        }
    };

    muteVideo = () => {
        if(this.localVideo){
            this.localVideo.setMuted(true)
            this.local_video_element.style.display = "none"
            this.video_muted = true;
        }
    };

    unmuteAudio = () => {
        if(this.localMicrophone){
            this.localMicrophone.setMuted(false)
            this.audio_muted = false;
        }
    };

    unmuteVideo = async () => {
        if(this.localVideo){
            await this.localVideo.setMuted(false)
            this.video_muted = false;
            this.local_video_element.style.display = "block"
            this.local_video_element.srcObject = new MediaStream([
                this.localVideo.getMediaStreamTrack(),
            ]);
            await this.local_video_element.play();
        }
    };

    setCamera = async (device_id) => {
        if(this.localVideo){
            await this.localVideo.setDevice(device_id);
            this.local_video_element.srcObject = new MediaStream([
                this.localVideo.getMediaStreamTrack(),
            ]);
            await this.local_video_element.play();
        }
    };

    setMicrophone = async (device_id) => {
        if(this.localMicrophone){
            await this.localMicrophone.setDevice(device_id);
        }
    };

    setPlaybackDevice = async (device_id) => {
        if(this.localMicrophone){
            await this.localMicrophone.setPlaybackDevice(device_id);
        }
    };

    currentCamera = () => {
        return this.localVideo.getMediaStreamTrack().getSettings().deviceId;
    };

    currentMicrophone = () => {
        return this.localMicrophone.getMediaStreamTrack().getSettings().deviceId;
    };

    handleUserJoin = (remoteUser) => {};

    sendCustomEvent = async (id, functionName, functionArgs) => {
        this.q.push({ id, functionName, functionArgs });
    };

    registerCustomEventHandler = (id, object) => {
        this.customEventHandlerMap.set(id, object);
    };

    startScreenShare = async () => {
        try {
            if (this.screenTrack)
                await this.rtc_client_screen.unpublish(this.screenTrack);
        } catch (e) {}
        //added condition to catch pre meeting clicks - could add a modal if needed to say "join first"
        if (this.rtm_joined) {
            this.screenTrack = await AgoraRTC.createScreenVideoTrack();
            await this.rtc_client_screen.publish(this.screenTrack);

            console.log(this.screenTrack)

            // Start own stream
            const video = document.getElementById("screen-share");
            const track = this.screenTrack._mediaStreamTrack;
            video.srcObject = new MediaStream([track]);
            await video.play();

            this.onRemoteUserStartScreenShare.trigger();

        }
    };
}

// if(!videoDevice || !audioDevice || !state || !state.devices){
//     return null;
// }
