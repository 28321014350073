import { useEffect, useRef } from "react";
import * as THREE from 'three'
import { Box } from "@mui/material";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";


function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
}

export default function TransitionLobbyOverlay(props){

    const mount = useRef(null);

    useEffect(() => {
        let width = mount.current.clientWidth
        let height = mount.current.clientHeight
        let frameId

        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
        const renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: "high-performance", })
        renderer.setPixelRatio( window.devicePixelRatio );
        // renderer.toneMapping = THREE.ReinhardToneMapping;

        const geometry = new THREE.BoxGeometry(1, 1, 1)
        const material = new THREE.MeshBasicMaterial( { color: 0x008693, opacity: 0.5, transparent: true } )
        const cube = new THREE.Mesh(geometry, material);
        const edges = new THREE.EdgesGeometry(geometry);
        const edgeMesh = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: new THREE.Color("white"), width: 1.5 }))
        cube.add(edgeMesh)
        scene.add(cube)

        // const geometry2 = new THREE.BoxGeometry(1, 1, 1)
        // const material2 = new THREE.MeshBasicMaterial( { color: 0xe69138, opacity: 0.5, transparent: true } )
        // const cube2 = new THREE.Mesh(geometry, material2);
        // const edges2 = new THREE.EdgesGeometry(geometry2);
        // const edgeMesh2 = new THREE.LineSegments(edges2, new THREE.LineBasicMaterial({ color: new THREE.Color("white"), width: 0.3 }))
        // cube2.add(edgeMesh2)
        // cube2.scale.y =  0.2;
        // cube2.scale.x =  0.2;
        // cube2.scale.z =  0.2;
        // cube2.rotation.x =  THREE.Math.degToRad(45);
        // cube2.rotation.y =  THREE.Math.degToRad(45);
        // cube2.rotation.z =  0.0;
        // scene.add(cube2)

        const geometry3 = new THREE.SphereGeometry(1.0, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
        const material3 = new THREE.MeshBasicMaterial( { color: 0xffffff } )
        const sphere = new THREE.Mesh(geometry3, material3);
        // sphere.scale.y =  0.2;
        // sphere.scale.x =  0.2;
        // sphere.scale.z =  0.2;
        sphere.position.z =  -80.0;
        scene.add(sphere)

        camera.position.z = 3;

        const composer = new EffectComposer( renderer );
        composer.setSize(width, height)

        const renderPass = new RenderPass( scene, camera );
        composer.addPass( renderPass );

        const bloomPass = new UnrealBloomPass(
            new THREE.Vector2( width, height )
        );
        bloomPass.threshold = 0.1;
        bloomPass.strength = 1;
        bloomPass.radius = 2;
        composer.addPass( bloomPass );

        renderer.setClearColor('rgb(0,0,0)')
        renderer.setSize(width, height)

        const renderScene = () => {
            composer.render()
        }

        const handleResize = () => {
            width = mount.current.clientWidth
            height = mount.current.clientHeight
            renderer.setSize(width, height)
            composer.setSize(width, height)
            camera.aspect = width / height
            camera.updateProjectionMatrix()
            renderScene()
        }

        const animate = () => {
            renderScene()

            const now = Date.now() / 1000;
            const speed = (now % 2 === 0) ? 0.05 : -0.05;

            cube.rotation.y += speed;
            cube.rotation.z += speed;
            cube.rotation.x += speed;

            // cube2.scale.x += speed*.01;
            // cube2.scale.y += speed*.01;
            // cube2.scale.z += speed*.01;
            // cube.position.y =  randomNumber(0.0,0.5)

            frameId = window.requestAnimationFrame(animate)
        }

        const start = () => {
            if (!frameId) {
                frameId = requestAnimationFrame(animate)
            }
        }

        const stop = () => {
            cancelAnimationFrame(frameId)
            frameId = null
        }

        mount.current.appendChild(renderer.domElement)
        window.addEventListener('resize', handleResize)
        start()

        return () => {
            stop()
            window.removeEventListener('resize', handleResize)
            geometry.dispose()
            material.dispose()
        }
    }, [])

    return <Box ref={mount} width="100%" height="100%" />
}