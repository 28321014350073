import { makeAutoObservable } from "mobx";
import { createTheme } from "@mui/material/styles";
import { IsMobile } from "../libs/BrowserLib";

const defaultTheme = createTheme({
        "palette": {
            "primary": {
                "main": "#121331"
            },
            "secondary": {
                "main": "#cb04ff"
            }
        }
    });

class App {

    theme = defaultTheme;
    isMobile = false;

    constructor() {
        makeAutoObservable(this);
        this.isMobile = IsMobile();
    }

    setAppTheme(theme){
        this.theme = createTheme(theme);
    }
}

export default new App()