import { detect } from "detect-browser";

export function IsMobile(){
    const browser = detect();

    let mobile = false;

    if(browser){
        switch (browser.os){
            //Todo - need a default case to stop warnings - but this does not work!
            // default: // Do nothing
            case "iOS":
            case "android":
            case "Android OS":
            case "BlackBerry OS":
            case "Windows Mobile":
                mobile = true;
        }
    }

    return mobile;
}

export function IsBrowserCompatible() {
    const browser = detect();

    let compatible = true;

    // handle the case where we don't detect the browser
    if (browser) {

        console.log(browser.name);
        console.log(browser.version);
        console.log(browser.os);

        //the debug modal is in: "dynamic scene"

  //TODO - it is preferable to have a catch-all windows id for the unfortunates on windows 8, and the nutters on W11
  //windows  firefox      { "name": "firefox", "version": "94.0.0", "os": "Windows 10", "type": "browser" }
  //windows  chrome       { "name": "chrome", "version": "95.0.4638", "os": "Windows 10", "type": "browser" }
  //windows  edge         { "name": "edge-chromium", "version": "95.0.1020", "os": "Windows 10", "type": "browser" }
  //windows  opera        { "name": "opera", "version": "74.0.3911", "os": "Windows 10", "type": "browser" }
  //windows  explorer - can't even run
        if (browser.os === "Windows 10" && browser.name === "edge-chromium") compatible = true;
        if (browser.os === "Windows 10" && browser.name === "chrome") compatible = true;
        if (browser.os === "Windows 10" && browser.name === "firefox") compatible = false;
        if (browser.os === "Windows 10" && browser.name === "opera") compatible = true;

        // ipad   safari      "name": safari / "version": XX.X.X / "os"=Mac OS / "type": browser
        // ipad   chrome      "name": crios / "version": XX.X.X / "os"=iOS / "type": browser   //***** so we lose chrome on ipad for now
        // ipad   firfefox    "name": safari / "version": XX.X.X / "os"=Mac OS / "type": browser
        // OSX  safari      { "name": "safari", "version": "94.0.0", "os": "Mac OS", "type": "browser" }//not working on my mbp
        // OSX  chrome      { "name": "chrome",  "version": "94.0.0", "os": "Mac OS", "type": "browser" }
        // OSX  firefox     { "name": "firefox", "version": "94.0.0", "os": "Mac OS", "type": "browser" }
        // OSX  opera       { "name": "opera",   "version": "94.0.0", "os": "Mac OS", "type": "browser" }
        // TODO -  iPad and Mac = a bit confusing - we need a way to detect we are on an ipad
        if (browser.os === "Mac OS" && browser.name === "safari") compatible = true;
        if (browser.os === "Mac OS" && browser.name === "crios") compatible = true;
        if (browser.os === "Mac OS" && browser.name === "fxios") compatible = false;
        if (browser.os === "Mac OS" && browser.name === "opera") compatible = true;

        // iphone safari      "name": iOS   / "version": XX.X.X / "os"=iOS / "type": browser
        // iphone chrome      "name": crios / "version": XX.X.X / "os"=iOS / "type": browser
        // iphone firefox     "name": fxios / "version": XX.X.X / "os"=iOS / "type": browser
        if (browser.os === "iOS" && browser.name === "ios") compatible = true;
        if (browser.os === "iOS" && browser.name === "crios") compatible = true;  //also iPad
        if (browser.os === "iOS" && browser.name === "fxios") compatible = false;

        //there are probably a few more weird Android Browsers to check out
        // android native??    "name": chromium-webview   / "version": XX.X.X / "os"=Android OS / "type": browser
        // android samsung     "name": samsung            / "version": XX.X.X / "os"=Android OS / "type": browser
        // android chrome      "name": chrome             / "version": XX.X.X / "os"=Android OS / "type": browser
        // android firefox     "name": chromium-webview   / "version": XX.X.X / "os"=Android OS / "type": browser //zonevs not loading at all?
        if (browser.os === "Android OS" && browser.name === "chromium-webview") compatible = true;
        if (browser.os === "Android OS" && browser.name === "samsung") compatible = true;
        if (browser.os === "Android OS" && browser.name === "chrome") compatible = true;
        if (browser.os === "Android OS" && browser.name === "firefox") compatible = false;
        if (browser.os === "Android OS" && browser.name === "opera") compatible = true;












    }

    console.info(`Browser compatible: ${compatible}`)
    return compatible;
}
