import Draggable from "./Draggable";
import Component from "./Component";
import Hoverable from "./Hoverable";
import Multiplayer from "./Multiplayer2";
import * as THREE from "three";
// import Clickable from "./Clickable";

export default class DraggableObject extends Component {
    lastUpdateTime = 0;
    lerpAlpha = 0.03;


    constructor(id, object, camera) {
        super();

        this.id = id;
        this.object = object;
        this.camera = camera;

        Multiplayer.instance.registerCustomEventHandler(this.id, this);

        const showHelp = () => {
            document.getElementById("help-text").style.display = "block";
            document.getElementById("help-text").innerHTML = "Click and drag to move this item";
        };
        const hideHelp = () => (document.getElementById("help-text").style.display = "none");

        // new Hoverable(object, () => {
        //     document.body.style.cursor = "move";
        // });
        new Hoverable(
            this.object,
            () => {
                document.body.style.cursor = "move";
                // this.objectSpritePDF.scale.set(0.45, 0.45, 0.45);
                showHelp();
            },
            () => {
                // this.objectSpritePDF.scale.set(0.4, 0.4, 0.4);
                hideHelp();
            }
        );

        this.plane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(8, 8, 1, 1),
            new THREE.MeshBasicMaterial({ color: "red", wireframe: true })
        );

        this.plane.position.copy(this.object.position);
        this.plane.visible = false;
        this.raycaster = new THREE.Raycaster();

        const draggable = new Draggable(object, (instance, { x, y, z }) => {
            object.parent.add(this.plane);
            this.plane.lookAt(this.camera.position);
            this.raycaster.set(
                this.camera.position,
                new THREE.Vector3(x, y, z).sub(this.camera.position).normalize()
            );

            const intersects = this.raycaster.intersectObject(this.plane);

            if (intersects.length > 0) {
                const intersect = intersects[0];
                // NOTE(george): the raycaster gives back positions slightly offset behind the plane, so here we build a more correct position to move the object to
                const newPos = this.camera.position
                    .clone()
                    .add(
                        intersect.point
                            .clone()
                            .sub(this.camera.position)
                            .normalize()
                            .multiplyScalar(
                                this.plane.position.distanceTo(
                                    this.camera.position
                                )
                            )
                    );

                this.object.position.copy(newPos);
                console.log(`drag position hint`);
                console.log(`"position": [${newPos.x.toFixed(3)},${newPos.y.toFixed(3)},${newPos.z.toFixed(3)}],`);
                this.plane.position.copy(newPos);

                const timeNow = new Date().getTime();

                if (this.lastUpdateTime + 150 < timeNow) {
                    this.lastUpdateTime = timeNow;
                    Multiplayer.instance.sendCustomEvent(
                        this.id,
                        "moveRemote",
                        {
                            x,
                            y,
                            z,
                        }
                    );
                }
            }
            object.parent.remove(this.plane);
        });

        draggable.unrestrictred = false;

    }

    moveRemote({ x, y, z }) {
        this.remoteTargetPosition = new THREE.Vector3(x, y, z);
    }

    update(delta) {
        if (this.remoteTargetPosition) {
            if (
                this.object.position.distanceTo(this.remoteTargetPosition) <=
                0.2
            ) {
                this.remoteTargetPosition = undefined;
            } else {
                this.object.position.lerp(
                    this.remoteTargetPosition,
                    this.lerpAlpha
                );
            }
        }
    }
}
